<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5">
      <div class="d-sm-flex align-center">
        <div class="ml-3">
          <h2 class="font-weight-regular title">Inscripciones</h2>
          <h6 class="body-2 mb-1 subtitle-2 font-weight-light">Datos de inscritos</h6>
        </div>
        <v-spacer></v-spacer>
        <!-- <div class="ml-auto d-flex justify-end">
          <div style="width: 150px" class="mt-4">
            <v-select
              class="pt-0 mt-0"
              v-model="select"
              :items="items"
              item-text="state"
              item-value="abbr"
              label="Select"
              persistent-hint
              return-object
              single-line
            ></v-select>
          </div>
        </div> -->
        <div class="ml-auto mt-4 mt-md-0">
          <div style="width:150px">
            <v-btn :to="{name: 'NewInscription'}" class="mx-4" color="primary">
              <v-icon>mdi-plus</v-icon>
              Agregar
            </v-btn>
          </div>
        </div>
      </div>
    <!-- Table -->
      <v-data-table
        :headers="headerArray"
        :items="extractItems"
        hide-default-footer
        :page.sync="page"
        :items-per-page="pageSize"
        :server-items-length="totalPages"
        :loading="loading"
        @click:row="showInscription"
      >
        <template v-slot:item.estado="{ item }">
          <v-chip
            class="state px-3 flex-grow-1"
            :class="{'state__listo': item.estado === 'LISTO', 'state__verificado': item.estado === 'VERIFICADO', 'state__noprocede': item.estado === 'NO_PROCEDE', 'state__recibido': item.estado === 'RECIBIDO'}"
          >{{ getStatus(item) }}</v-chip>
        </template>
        <template v-slot:item.nombre="{ item }">
          <div class="d-flex" style="cursor: pointer;">
            <!-- <img
              :src="item.img || require('@/assets/images/users/defaultimg.png')"
              alt="user"
              class="rounded-circle"
              width="36"
            /> -->
            <div class="my-auto">
              <h4 class="font-weight-medium text-no-wrap">
                {{ item.nombres }}
              </h4>
              <h6 class="font-weight-medium text-no-wrap">
                {{ item.apellidos }}
              </h6>
              <p class="font-weight-medium text-no-wrap ma-0  enrollment_date" v-if="item.fecha_matricula !== null">
                Matriculado el {{ item.fecha_matricula | moment("DD/MM/YYYY [a las]  HH:mm")}}
              </p>
            </div>
          </div>
        </template>
        <!-- customizing pagination -->
        <template v-slot:footer="slotProps">
            <div class="pagination d-flex ml-auto mr-0 mt-5">
                <v-btn icon @click="page--" :disabled="page === 1"><v-icon>mdi-chevron-left</v-icon></v-btn>
                <p class="ma-auto">{{ slotProps.props.pagination.page }} de {{ totalPages }}</p>
                <v-btn icon @click="page++" :disabled="next === null"><v-icon>mdi-chevron-right</v-icon></v-btn>
            </div>
          </template>
        <!-- customizing pagination -->
      </v-data-table>
    <!-- Table -->
    </v-card-text>
  </v-card>
</template>

<script>
import defaltUserImage from '@/assets/images/users/default-user.png'
import moment from 'moment'
import NProgress from "nprogress"
import { mapMutations, mapState } from 'vuex'
import axios from 'axios'

export default {
  name: "TheInscriptionAvailibility",
  data: () => ({
    defaltUserImage,
    page: 1,
    pageCount: 0,
    loading: false,
    select: { state: "Febrero", abbr: "FL" },
    items: [
      { state: "Enero", abbr: "FL" },
      { state: "Febrero", abbr: "GA" },
      { state: "Marzo", abbr: "NE" },
      { state: "Abril", abbr: "CA" }
    ],
    inscriptions: [],
    pageSize: 15,
    totalPages: 1,
    next: null,
    weekAgoDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    headerArray: [
      { text: 'Nombre', value: 'nombre', sortable: false, align: 'start' },
      { text: 'Seccion', value: 'seccion', sortable: false, align: 'start' },
      { text: 'Celular', value: 'celular', sortable: false, align: 'center' },
      { text: 'Universidad', value: 'universidad', sortable: false, align: 'end' },
      { text: 'Departamento', value: 'departamento', sortable: false, align: 'end' },
      { text: 'Estado', value: 'estado', sortable: false, align: 'end' }
    ],
    regions: [],
    firstLoad: true
  }),
  watch: {
    async page() {
      this.loading = true
      if (this.searchGlobal) {
        await this.searchInscriptions(this.page)
      } else {
        await this.fetchInscriptions(this.page)
      }
      this.loading = false
    },
    async searchGlobal () {
      this.$router.replace(`/dashboard/inscriptions/?search=${this.searchGlobal}&page=${this.page}`)
      // await this.searchInscriptions(this.page)
    },
    '$route.query': {
      async handler () {
        await this.searchInscriptions(this.page)
      },
      deep: true,
    }
  },
  computed: {
    ...mapState(['searchGlobal']),
    desserts () {
      return Array(25).fill(this.dessertOne)
    },
    extractItems () {
      return this.inscriptions.map(item => {
        return {
          id: item.id,
          nombres: item.nombres,
          apellidos: item.apellidos,
          seccion: item.seccion,
          celular: item.celular,
          universidad: item.institucion,
          departamento: item.region,
          estado: item.estado,
          fecha_matricula: item.fecha_matricula,
          img: ""
        }
      })
    }
  },
  methods: {
    // async getRegion (itemId) {
    //   const name = await this.regions.find(item => item.id === itemId).name
    //   return name
    // },
    ...mapMutations(['SET_SEARCH']),
    async searchInscriptions (page) {
      const response = await axios.get(`api/admin/matriculas/formularios/?search=${this.searchGlobal}&page=${page}&page_size=${this.pageSize}`)
      this.inscriptions = response.data.results
    },
    async fetchRegions() {
      const regionsResponse = await this.$http.get('api/ciudades/region/')
      this.regions = regionsResponse.data
    },
    async fetchInscriptions(page) {
      const url = `api/admin/matriculas/formularios/?page_size=${this.pageSize}&page=${page}`
      const inscriptionsResponse = await this.$http.get(url)
      this.next = inscriptionsResponse.data.next
      this.inscriptions = inscriptionsResponse.data.results
      for (let index = 0; index < this.inscriptions.length; index++) {
        this.inscriptions[index].region = await this.regions.find(item => item.id === this.inscriptions[index].region)?.name
      }
      this.totalPages = Math.ceil(inscriptionsResponse.data.count / this.pageSize)
    },
    getColor(inscription) {
      const inscriptionDate = moment(inscription.created).format("YYYY-MM-DD");
      if (inscription.estado === 'RECIBIDO' && moment(inscriptionDate).isBefore(this.weekAgoDate)) {
        return 'warning'
      } else if (inscription.estado === 'RECIBIDO') {
        return 'info'
      } else if (inscription.estado === 'LISTO') {
        return 'success'
      } else if (inscription.estado === 'NO_PROCEDE') {
        return 'error'
      }
    },
    getStatus(inscription) {
      const inscriptionDate = moment(inscription.created).format("YYYY-MM-DD");
      if (inscription.estado === 'RECIBIDO' && moment(inscriptionDate).isBefore(this.weekAgoDate)) {
        return 'Pendiente'
      } else if (inscription.estado === 'RECIBIDO') {
        return "Recibido"
      } else if (inscription.estado === 'LISTO') {
        return "Listo"
      } else if (inscription.estado === 'VERIFICADO') {
        return "Verificado"
      } else if (inscription.estado === 'NO_PROCEDE') {
        return "No procede"
      }
    },
    showInscription(inscription) {
      this.$router.push({name: 'UpdateInscription', params: {id: inscription.id}})
    }
  },
  async mounted() {
    await NProgress.start()
    await this.fetchRegions()
    await this.fetchInscriptions(this.page)
    if (this.$route.query.search) {
      await this.SET_SEARCH(this.$route.query.search)
    }
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    }
    this.firstLoad = false
    await NProgress.done()
  }
};
</script>

<style lang="scss" scoped>
.table-text {
  color: #647485;
  font-weight: normal;
  letter-spacing: 0;
  font-size: 0.875rem !important;
  line-height: 1.375rem;
  font-family: "Poppins", sans-serif !important;
}
.state {
  max-height: 23px;
  color: #FFF;
  border-radius: 5px;
  &__recibido {
    background: #1E88E5 !important;
  }
  &__listo {
    background: #26C6DA !important;
  }
  &__verificado {
    background: #4CAF50 !important;
  }
  &__noprocede {
    background: #FC4B6C !important;
  }
}
.enrollment_date {
  color: #1E88E5;
  font-size: 12px;
}
</style>
